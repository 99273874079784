<template>
  <div>
    <div v-if="taxHistory.length">
      <base-tax-form
        :data="editData"
        @cancel="$emit('cancel')"
        update
        :edit="true"
        @submit="updateData"
        @updateYear="onYearUpdate"
      >
        <template v-slot:submit-btn> Update <v-icon>mdi-content-save</v-icon></template>
        <template v-slot:alert>
          <slot name="alert"></slot>
        </template>
      </base-tax-form>
    </div>
    <div v-else class="pa-3">
      <v-alert outlined color="green darken-1">
        You have not entered any tax information, so there is nothing to edit.<br /><br />

        Click on the
        <span style="color: rgba(0, 0, 0, 0.54)"
          >Add <span class="d-sm-none d-inline d-lg-inline"> New Tax </span>
          <v-icon>mdi-plus-circle</v-icon></span
        >
        tab above to enter tax information. Once tax information is saved, you will be able to come
        back to it and edit it.
      </v-alert>
    </div>
  </div>
</template>

<script>
import BaseTaxForm from './BaseTaxForm';
import { mapMutations, mapGetters } from 'vuex';
export default {
  components: { BaseTaxForm },
  mounted() {
    this.setData();
  },
  data: () => ({
    editData: null
  }),
  computed: {
    taxIndex() {
      return this.activeTax;
    },
    ...mapGetters(['taxHistory', 'activeTax', 'activeTaxData'])
  },
  methods: {
    setData() {
      if (this.activeTax != -1) {
        this.editData = this.copyObject(this.activeTaxData);
      } else {
        this.editData = null;
      }
    },
    updateData(data) {
      if (this.activeTax != -1) {
        this.updateTaxHistory(this.copyObject(data.formData));
      }
      this.$emit('updated');
      this.updateMetrics({ feature: 'tax', action: 'update' });
    },
    onYearUpdate(year) {
      this.taxHistory.forEach((e, i) => {
        if (e.year == year) {
          this.setActiveTax(i);
        }
      });
    },
    ...mapMutations(['updateTaxHistory', 'setActiveTax'])
  },
  watch: {
    taxIndex() {
      this.setData();
    }
  }
};
</script>

<style></style>

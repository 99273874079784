<template>
  <v-main>
    <v-container>
      <feature-breadcrumb :feature="fBreadcrumb">
        <template #action>
          <ParcelReference :reference="parcelReference" small />
          <FeatureGalleryButton v-model="gallery" class="ml-1" icon :count="galleryCount" />
        </template>
      </feature-breadcrumb>
      <v-alert max-width="400" class="mx-auto" v-model="saved" text type="success"
        >Data saved</v-alert
      >
      <v-row>
        <v-col cols="12" sm="5">
          <FeatureGallery feature="Taxes" v-model="gallery" :count.sync="galleryCount" />
          <v-card id="addEdit" class="mx-auto" elevation="3">
            <v-card-title
              class="primary--text cursor-pointer d-sm-none d-flex"
              @click="dialogs.addData = !dialogs.addData"
              >Add/Edit Tax
              <v-btn icon>
                <v-icon class="primary--text">{{
                  dialogs.addData ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon></v-btn
              ></v-card-title
            >

            <v-container v-if="dialogs.addData">
              <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                <v-tab>
                  <span class="d-sm-flex d-lg-none d-none">Add </span>
                  <span class="d-sm-none d-flex d-lg-flex">Add New Tax</span>
                  <v-icon>mdi-plus-circle</v-icon></v-tab
                >
                <v-tab :disabled="!permissions.update">
                  <span class="d-sm-flex d-lg-none d-none">Edit </span>
                  <span class="d-sm-none d-flex d-lg-flex">Edit Existing Tax</span>
                  <v-icon>mdi-pencil</v-icon></v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <tax-add-form class="mt-3" @updated="createData()"></tax-add-form>
                </v-tab-item>
                <v-tab-item>
                  <tax-edit-form @updated="taxUpdated()" @cancel="cancelEdit()" class="mt-3">
                    <template v-slot:alert>
                      <v-fade-transition
                        ><v-alert v-if="updated" text type="success"
                          >Tax data updated</v-alert
                        ></v-fade-transition
                      >
                    </template>
                  </tax-edit-form>
                </v-tab-item>
              </v-tabs-items>
              <!-- <div class="ml-5 mr-5 mt-2 mb-2"></div> -->
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" sm="7">
          <tax-history-chart :parcel="latestParcel"> </tax-history-chart>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <tax-history :loading="loading" @updated="saveData()"></tax-history>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import TaxAddForm from '@/components/Tax/TaxAddForm';
import TaxEditForm from '@/components/Tax/TaxEditForm';
import TaxHistory from '@/components/Tax/TaxHistory';
import TaxHistoryChart from '@/components/Tax/TaxHistoryChart';
import tax from '@/mixins/tax';
import parcel from '@/mixins/parcel';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  metaInfo: {
    title: 'Taxes'
  },
  mixins: [tax, parcel],
  data: () => ({
    gallery: false,
    galleryCount: 0,
    dialogs: {
      addData: true
    },
    loading: true,
    loadings: {
      save: false,
      clear: false
    },
    saved: false,
    fBreadcrumb: {
      text: 'Taxes',
      disabled: true,
      to: '#'
    },
    saving: false,
    tab: null,
    updated: false
  }),
  components: {
    TaxAddForm,
    TaxHistory,
    TaxHistoryChart,
    TaxEditForm,
    FeatureBreadcrumb: () => import('@/components/Core/FeatureBreadcrumb.vue'),
    FeatureGallery: () => import('../../../components/Gallery/FeatureGallery.vue'),
    FeatureGalleryButton: () => import('../../../components/Gallery/FeatureGalleryButton.vue')
  },
  computed: {
    parcelReference() {
      const ref = this.$store.state.parcel.latestParcel?.reference;
      if (ref) {
        return ref;
      }
      return '';
    },
    ...mapGetters(['latestParcel', 'activeTax']),
    ...mapState(['permissions'])
  },
  async mounted() {
    let taxData = await this.getTaxHistory(this.$route.params.id);
    if (taxData) {
      this.$store.commit('setTaxHistory', taxData.data);
    }
    this.loading = false;
  },
  destroyed() {
    this.$store.commit('setTaxHistory', []);
  },
  methods: {
    async createData() {
      this.saveData();
    },
    async saveData() {
      this.saving = true;
      await this.saveTaxHistory(this.$route.params.id, this.$store.getters.taxHistory);
      this.saving = false;
    },
    taxUpdated() {
      this.saveData();
      this.updated = true;
      setTimeout(() => {
        this.updated = false;
      }, 3000);
    },
    cancelEdit() {
      this.setActiveTax(-1);
    },
    ...mapMutations(['setActiveTax'])
  },
  watch: {
    activeTax() {
      this.tab = 1;
      if (this.activeTax != -1) {
        this.$vuetify.goTo('#addEdit');
      }
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .address {
    margin-left: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .breadcrumbs {
    justify-content: space-between !important;
  }
}
</style>

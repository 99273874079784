import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.dialogs.success),callback:function ($$v) {_vm.$set(_vm.dialogs, "success", $$v)},expression:"dialogs.success"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialogs.success = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"success--text text-center"},[_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]),_vm._v(" Your Tax for "+_vm._s(_vm.year)+" has been saved, and can be viewed in the table at the bottom. You can Edit this anytime or Add another year’s Tax. "),_c('div',{staticClass:"mt-3"},[_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"info","text":"","small":""},on:{"click":function($event){return _vm.doNotShowAgain()}}},[_vm._v("Do not show this again")]),_c('br'),_c(VBtn,{staticClass:"mt-2 text-capitalize",attrs:{"color":"secondary","dark":""},on:{"click":function($event){_vm.dialogs.success = false}}},[_vm._v("Close")])],1)],1)],1)],1),_c('base-tax-form',{on:{"submit":_vm.addTaxHistory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-dialog v-model="dialogs.success" max-width="500">
      <v-card class="mx-auto">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.success = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="success--text text-center">
          <v-icon color="success">mdi-check-circle</v-icon>
          Your Tax for {{ year }} has been saved, and can be viewed in the table at the bottom. You
          can Edit this anytime or Add another year’s Tax.
          <div class="mt-3">
            <v-btn @click="doNotShowAgain()" color="info" class="text-capitalize" text small
              >Do not show this again</v-btn
            >
            <br />
            <v-btn
              color="secondary"
              dark
              class="mt-2 text-capitalize"
              @click="dialogs.success = false"
              >Close</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <base-tax-form @submit="addTaxHistory"></base-tax-form>
  </div>
</template>

<script>
import BaseTaxForm from './BaseTaxForm';
import tax from '@/mixins/tax';
export default {
  mixins: [tax],
  components: {
    BaseTaxForm: BaseTaxForm
  },
  created() {
    this.doNotShowPopup = !!localStorage.getItem('doNotShowTaxPopup');
  },
  data() {
    return {
      doNotShowPopup: false,
      dialogs: {
        success: false
      },
      year: ''
    };
  },
  methods: {
    addTaxHistory(e) {
      this.year = e.formData.year;
      this.$store.commit('addTaxHistory', { ...e.formData });
      e.resetForm();
      this.$emit('updated');

      this.updateMetrics({ feature: 'tax', action: 'create' });

      if (this.doNotShowPopup) {
        return;
      }
      this.dialogs.success = true;
      setTimeout(() => {
        this.dialogs.success = false;
      }, 10000);
    },
    doNotShowAgain() {
      localStorage.setItem('doNotShowTaxPopup', JSON.stringify(true));
      this.doNotShowPopup = true;
      this.dialogs.success = false;
    }
  }
};
</script>

<style></style>
